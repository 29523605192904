import React, { useContext } from "react";
import "./Portfolio.css";

import Payment_method from './payment_method.pdf';

import { themeContext } from "../../Context";




const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  
  if (darkMode) {
    console.log('Dark mode is enabled')
  }
  return (
    <>
        
       
                
            <div class="requisites">
                <div class="column1">
                    <h3>Signal Subscription</h3>
                    <div className=" columnbody"> 
                    <ul>
                      
                        <li>Monthly Plan Subscription</li>
                        
                        <li>Receive Signals Via Mail, Instagram or Whatsapp</li>
                        
                        <li>Daily Signal Frequency</li>
                        <li>Risk and Money Management</li>
                        <li>24 hour Support from Or Professional Team</li>
                      </ul>
                      <ul>
                        <li>Next Step Explained On your Mail Box</li>

                        </ul>
                      <ul>100$</ul>
                    </div> 
                    <a href={Payment_method} download>
                    <button className="button n-button1">Subscribe</button>
                    </a>
                </div>
                <div class="column1">
                    <h3>Course Subscription</h3>
                    <div className="columnbody">
                    <ul>
                      
                        <li>Technical Analysis</li>
                        <li>Money Management</li>
                        <li>Trading Strategies</li>
                        <li>Market Mechanics</li>
                        
                        
                        
                      </ul>
                      <ul>
                        <li>Next Step Explained On your Mail Box</li>
                      </ul>
                      <ul>100$</ul>
                    </div>
                    <a href={Payment_method} download>
                    <button className="button n-button2">Subscribe</button>
                    </a>
                </div>
                
                <div class="column1">
                    <h3>Binary Trading Bot</h3>
                    <div className="columnbody">
                                        
                      <ul>
                      
                        <li>Approved binary trading bot</li>
                        <li>quick data analysis</li>
                    
                        <li>performance optimization</li>
                        <li>pre-programmed strategies</li>
                        <li>fast execution</li>
                        
                
                        
                      </ul>
                      <ul>
                        <li>Next Step Explained On your Mail Box</li>
                      </ul>
                      <ul >100$</ul>
                    </div>
                    <a href={Payment_method} download>
                    <button className="button n-button3">Subscribe</button>
                    </a>
                    
                    
                </div>
                
            </div>
           
    </>
)
};

export default Portfolio;
